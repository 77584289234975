import { useEffect } from "react";
import { Button } from "react-daisyui";
import { IconX } from "@tabler/icons-react";
import { ProfileFooter } from "./ProfileFooter";
import { Link, useLocation } from "react-router-dom";
import { SidebarMainMenu } from "./SidebarMainMenu";
import { SidebarAppsMenu } from "./SidebarAppsMenu";
import { useAuth } from "src/authentication/hooks/useAuth";
import { CopyrightFooter } from "src/privacy-policy/CopyrightFooter";

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: (open: boolean) => void;
}

export const Sidebar = ({ sidebarOpen, setSidebarOpen }: Props) => {
    const location = useLocation();

    const { person } = useAuth();

    useEffect(() => {
        setSidebarOpen(false);
    }, [location.pathname, setSidebarOpen]);

    return (
        <aside
            className={[
                "fixed top-0 left-0 w-full md:w-80 h-screen lg:translate-x-0 border-r lg:shadow-none pb-16 lg:pb-2",
                sidebarOpen ? "md:shadow-lg z-30" : "-translate-x-full",
            ]
                .filter(Boolean)
                .join(" ")}
            aria-label="Sidebar"
        >
            <div className="h-full overflow-y-auto bg-gray-50 dark:bg-gray-800 flex flex-col">
                <div className="p-3">
                    <div className="flex gap-2 justify-between lg:justify-center">
                        <Link to="/" className="transition-all active:scale-95">
                            <img
                                src="https://www.lurganbaptist.church/wp-content/uploads/2022/04/lbc-logo-no-text.png"
                                alt=""
                                className="dark:invert h-12 pl-2 object-contain"
                            />
                        </Link>

                        <Button
                            type="button"
                            className="lg:hidden"
                            color="ghost"
                            onClick={() => setSidebarOpen(false)}
                            startIcon={<IconX />}
                        >
                            <span className="hidden">Close</span>
                        </Button>
                    </div>

                    {person && <ProfileFooter person={person} />}
                </div>

                <div className="grow p-3">
                    <SidebarMainMenu />

                    {person && person.user.roles.length > 0 && <SidebarAppsMenu />}
                </div>

                <div className="p-3 bg-base-200 border-t border-t-base-300 text-xs text-center">
                    <CopyrightFooter />
                </div>
            </div>
        </aside>
    );
};
