import { Navigate, RouteObject } from "react-router-dom";

export const childProtectionRoutes: RouteObject[] = [
    {
        children: [
            {
                path: "forms",

                children: [
                    {
                        path: "parental-consent-forms",

                        children: [
                            {
                                index: true,
                                lazy: () => import("./forms/parental-consent-forms/ManageParentalConsentFormsScreen"),
                            },
                            {
                                path: "view-form/:parentalConsentFormId",
                                lazy: () => import("./forms/parental-consent-forms/ViewParentalConsentFormScreen"),
                            },

                            {
                                path: "submit-paper-form",
                                children: [
                                    { index: true, element: <Navigate to="about-your-child" replace /> },

                                    {
                                        lazy: async () => {
                                            const { Component } = await import(
                                                "../public/consent-and-medical/give-parental-consent/PublicGiveParentalConsentScreen"
                                            );

                                            return {
                                                Component: () => (
                                                    <Component
                                                        heading="Enter a paper parental consent form"
                                                        headerArea="If a consent form can't be filled in by the parent themselves, then this form can be used to submit a paper record for processing."
                                                    />
                                                ),
                                            };
                                        },
                                        children: [
                                            {
                                                path: "about-your-child",
                                                lazy: () =>
                                                    import(
                                                        "../public/consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormAboutYourChildStep"
                                                    ),
                                            },
                                            {
                                                path: "your-details",
                                                lazy: () =>
                                                    import(
                                                        "../public/consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormYourDetailsStep"
                                                    ),
                                            },
                                            {
                                                path: "consent",
                                                lazy: () =>
                                                    import(
                                                        "../public/consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormConsentStep"
                                                    ),
                                            },
                                            {
                                                path: "declaration",
                                                lazy: async () => {
                                                    const { Component } = await import(
                                                        "../public/consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormDeclarationStep"
                                                    );

                                                    return { Component: () => <Component paper /> };
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "medical-forms",

                        children: [
                            { index: true, lazy: () => import("./forms/medical-forms/ManageMedicalFormsScreen") },

                            { path: "view-form/:medicalFormId", element: <p>View form</p> },

                            {
                                path: "submit-paper-form",
                                lazy: () =>
                                    import("./forms/medical-forms/submit-paper-form/SubmitPaperMedicalFormPresenter"),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
