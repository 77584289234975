import { SubjectRawRule, ExtractSubjectType, PureAbility } from "@casl/ability";
import { unpackRules, PackRule } from "@casl/ability/extra";
import type { AppSubjects } from "@lbcrm-api/casl/app.subjects";
import axios, { Axios, isAxiosError } from "axios";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "src/casl/Action";
import { AblePerson, AuthenticationContext } from "./AuthenticationContext";
import { AbilityContext } from "./Can";
import { API_URL } from "src/core/constants";
import { User, signOut } from "firebase/auth";
import { firebaseAnalytics, firebaseAuth } from "../firebase";
import { toast } from "react-toastify";
import { LoadingScreen } from "src/core/components/LoadingScreen";
import { setUserId } from "firebase/analytics";
import { PrismaQuery, createPrismaAbility } from "@casl/prisma";

interface Props {}

const createAxios = () => {
    return axios.create({
        baseURL: API_URL,
        headers: {
            "X-Client-Version": import.meta.env.VITE_RELEASE_VERSION || "dev",
        },
    });
};

export const defaultAxios = createAxios();

export const AuthenticationProvider = (props: PropsWithChildren<Props>) => {
    const [initialised, setInitialised] = useState(false);
    const initialising = useRef(false);
    const [person, setPerson] = useState<AblePerson | null>();
    const [ability, setAbility] = useState<PureAbility<[Action, AppSubjects], PrismaQuery>>();
    const [authenticationError, setAuthenticationError] = useState<Error>();

    const axiosRef = useRef<Axios | undefined>(defaultAxios);

    const navigate = useNavigate();

    const logout = useCallback(
        async (recordFromState = false) => {
            await signOut(firebaseAuth);

            navigate("/login" + (recordFromState ? `?from=${encodeURIComponent(window.location.pathname)}` : ""));

            setPerson(undefined);
            setAuthenticationError(undefined);

            axiosRef.current = undefined;
        },
        [navigate]
    );

    const getProfile = useCallback(
        async (firebaseUser: User) => {
            const forcedNewToken = await firebaseUser.getIdToken();

            try {
                const response = await axios.get<{
                    person?: AblePerson;
                    rules: PackRule<SubjectRawRule<Action, ExtractSubjectType<AppSubjects>, PrismaQuery>>[];
                }>(API_URL + "/profile", {
                    headers: {
                        Authorization: "Bearer " + forcedNewToken,
                        "X-Client-Version": import.meta.env.VITE_RELEASE_VERSION || "dev",
                    },
                });

                const person = response.data.person;
                const rules = response.data.rules;

                axiosRef.current = createAxios();

                axiosRef.current.interceptors.request.use(
                    async (config) => {
                        if (config.headers) {
                            const token = await firebaseUser.getIdToken();

                            config.headers["Authorization"] = "Bearer " + token;
                        }

                        return config;
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                );

                axiosRef.current.interceptors.response.use(
                    (response) => {
                        return response;
                    },
                    async (error) => {
                        // Log out of firebase if a 401 was received
                        if (isAxiosError(error) && error.response?.status === 401) {
                            await logout(true);
                        }

                        return Promise.reject(error);
                    }
                );

                setPerson(person);

                const ability = createPrismaAbility<[Action, AppSubjects], PrismaQuery>(unpackRules(rules));

                // @ts-expect-error - missing private properties
                setAbility(ability);

                if (person) {
                    setUserId(firebaseAnalytics, person.id);
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 401) {
                        await logout(true);
                    }

                    setAuthenticationError(error);
                } else if (error instanceof Error) {
                    setAuthenticationError(error);
                } else {
                    setAuthenticationError(
                        new Error(
                            "An unknown error occurred while logging in. Please try again shortly, or contact someone at church."
                        )
                    );
                }
            }
        },
        [logout]
    );

    // Attempts to authenticate the user using the token in local storage
    useEffect(() => {
        if (initialised || initialising.current) {
            return;
        }

        async function initialise() {
            await firebaseAuth.authStateReady();

            const currentFirebaseUser = firebaseAuth.currentUser;

            try {
                if (currentFirebaseUser) {
                    await getProfile(currentFirebaseUser);
                }
            } catch {
                // TODO handle any initialisation error. This should probably clear the screen
            } finally {
                setInitialised(true);
                initialising.current = false;
            }
        }

        initialising.current = true;
        initialise();
    }, [initialised, getProfile]);

    const handleLogin = async () => {
        if (!firebaseAuth.currentUser) {
            return;
        }

        try {
            await getProfile(firebaseAuth.currentUser);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setAuthenticationError(error);
            } else if (error instanceof Error) {
                setAuthenticationError(error);
            } else {
                setAuthenticationError(
                    new Error(
                        "An unknown error occurred while logging in. Please try again shortly, or contact someone at church."
                    )
                );
            }
        }
    };

    const refreshProfile = async () => {
        try {
            if (!firebaseAuth.currentUser) {
                toast.warn(`Couldn't refresh your profile because you aren't signed in.`);
                return;
            }

            await getProfile(firebaseAuth.currentUser);
        } catch {
            toast.error("An error occurred while refreshing your profile. Please try again shortly.");
        }
    };

    if (!initialised) {
        return <LoadingScreen img />;
    }

    return (
        <AuthenticationContext.Provider
            value={{
                person,
                error: authenticationError,
                client: axiosRef.current,
                handleLogin,
                logout,
                refreshProfile,
            }}
        >
            {ability ? (
                <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
            ) : (
                props.children
            )}
        </AuthenticationContext.Provider>
    );
};
