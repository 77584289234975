import { Card as DaisyCard, CardProps } from "react-daisyui";

export const stringToColour = (str: string) => {
    const multipliers = [2, 3, 4]; // You can adjust these multipliers as needed

    let hash = 0;

    str.split("").forEach((char, index) => {
        hash = char.charCodeAt(0) + ((hash << multipliers[index % multipliers.length]) - hash);
    });

    let colour = "#";

    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;

        // Ensure the value is within a pastel-friendly range, e.g., between 128-255
        value = Math.floor(128 + value / 2);

        colour += value.toString(16).padStart(2, "0");
    }

    return colour;
};

export const colourClassForHex = (hex: string) => {
    // strip the leading # if it's there
    if (hex.slice(0, 1) === "#") {
        hex = hex.slice(1);
    }

    const rgb = parseInt(hex, 16);

    const redComponent = (rgb >> 16) & 0xff; // extract red
    const greenComponent = (rgb >> 8) & 0xff; // extract green
    const blueComponent = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * redComponent + 0.7152 * greenComponent + 0.0722 * blueComponent; // per ITU-R BT.709

    return luma < 140 ? "text-white" : "text-neutral";
};

interface Props extends CardProps {
    figureSeed?: string;
    smallFigure?: boolean;
}

export const Card = ({ className, children, figureSeed, smallFigure, ...props }: Props) => (
    <DaisyCard {...props} className={["border-base-300 shadow-sm bg-base-100", className].filter(Boolean).join(" ")}>
        {figureSeed && (
            <figure
                className={`glass ${smallFigure ? "h-2" : "h-32"}`}
                style={{ backgroundColor: stringToColour(figureSeed) }}
            />
        )}

        {children}
    </DaisyCard>
);

Card.displayName = "Card";

Card.Actions = DaisyCard.Actions;
Card.Body = DaisyCard.Body;
Card.Image = DaisyCard.Image;
Card.Title = DaisyCard.Title;
