import { RouteObject } from "react-router-dom";
import { routeCan } from "src/authentication/routeCan";
import { Action } from "src/casl/Action";

export const directoryRoutes: RouteObject[] = [
    { index: true, lazy: () => import("./PeopleDirectoryScreen"), handle: routeCan(Action.Manage, "Person") },

    { path: "add-person", lazy: () => import("./person/NewPersonScreen"), handle: routeCan(Action.Create, "Person") },

    {
        path: "merge-people",
        lazy: () => import("./merge-people/MergePeopleScreen"),
        handle: routeCan(Action.Manage, "Person"),
    },

    {
        path: "view-person/:personId",
        handle: routeCan(Action.Read, "Person"),
        lazy: () => import("./person/ViewPersonScreen"),
        children: [
            { index: true, lazy: () => import("./person/details/PersonDetails") },

            {
                path: "relationships",
                lazy: () => import("./person/relationships/PersonRelationshipsScreen"),
                handle: routeCan(Action.Read, "PersonResponsibilityRelationship"),
            },

            {
                path: "medical-notes",
                lazy: () => import("./person/medical-notes/PersonMedicalNotesTab"),
                handle: routeCan(Action.Read, "PersonMedicalNote"),
            },
        ],
    },

    {
        path: "view-person/:personId",
        handle: routeCan(Action.Read, "Person"),
        children: [
            {
                path: "update-details",
                lazy: () => import("./person/EditPersonScreen"),
                handle: routeCan(Action.Update, "Person"),
            },

            {
                path: "membership",
                children: [
                    {
                        index: true,
                        lazy: () => import("./person/memberships/components/PersonMembershipScreen"),
                    },
                    {
                        path: "application",
                        lazy: () => import("./person/memberships/management/application/MembershipApplicationScreen"),
                    },
                ],
            },
        ],
    },
];
