import { Fragment, PropsWithChildren, useCallback, useState } from "react";
import { Sidebar } from "./sidebar/Sidebar";
import { NavLink, Navigate, Outlet } from "react-router-dom";
import { Icon, IconMenu2 } from "@tabler/icons-react";
import { useAuth } from "src/authentication/hooks/useAuth";
import { useMainMenuItems } from "./sidebar/hooks/useMainMenuItems";

const renderNavLink = (
    to: string,
    IconComponent: Icon,
    text: string,
    disabled: boolean,
    onClick: () => void,
    end = false
) => (
    <NavLink
        to={to}
        end={end}
        onClick={onClick}
        className={({ isActive }) =>
            "group btm-nav-link text-center whitespace-nowrap" +
            (isActive && !disabled ? " font-bold text-primary dark:text-white" : "")
        }
    >
        {({ isActive }) => (
            <>
                <span
                    className={
                        "transition-all py-1 rounded-full " +
                        (isActive && !disabled
                            ? "bg-slate-300 px-6 dark:bg-slate-600"
                            : "group-hover:bg-slate-100 px-3 dark:group-hover:bg-slate-700")
                    }
                >
                    <IconComponent className="w-5 h-5" />
                </span>

                <span className="text-xs">{text}</span>
            </>
        )}
    </NavLink>
);

interface Props {}

export const NavBarLayout = (props: PropsWithChildren<Props>) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const closeSidebar = useCallback(() => setSidebarOpen(false), []);

    const { person } = useAuth();
    const menuItems = useMainMenuItems();

    // `from` is the `from` query parameter
    const from = new URLSearchParams(window.location.search).get("from");

    if (person === null) {
        return <Navigate to={`/set-up-your-profile${from ? `?from=${encodeURIComponent(from)}` : ""}`} replace />;
    }

    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="lg:ml-80 mb-20 lg:mb-2">{props.children ?? <Outlet />}</div>

            <footer className="btm-nav h-16 lg:hidden z-40 bg-slate-200 border-t border-t-slate-300 dark:bg-slate-800 dark:border-t-slate-500">
                {menuItems
                    .filter((menuItem) => menuItem.bottomNav)
                    .map((menuItem) => (
                        <Fragment key={menuItem.to}>
                            {renderNavLink(
                                menuItem.to,
                                menuItem.icon,
                                menuItem.shortLabel ?? menuItem.label,
                                sidebarOpen,
                                closeSidebar,
                                menuItem.bottomEnd
                            )}
                        </Fragment>
                    ))}

                <button
                    type="button"
                    className={`flex flex-col items-center justify-center ${
                        sidebarOpen ? "font-bold text-primary dark:text-white group" : "group"
                    }`}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                    <span
                        className={
                            "transition-all py-1 rounded-full " +
                            (sidebarOpen
                                ? "bg-slate-300 px-6 dark:bg-slate-600"
                                : "group-hover:bg-slate-100 px-3 dark:group-hover:bg-slate-700")
                        }
                    >
                        <IconMenu2 className="w-5 h-5" />
                    </span>

                    <span className="text-xs">More</span>
                </button>
            </footer>
        </>
    );
};
