type Nullish<T> = T | null | undefined;

export const classNames = (
    className: string,
    conditionalClassNames: Record<string, Nullish<boolean>>,
    ...additionalClassNames: (string | undefined)[]
) => {
    const classes = [className];

    for (const conditionalClassName in conditionalClassNames) {
        if (conditionalClassNames[conditionalClassName]) {
            classes.push(conditionalClassName);
        }
    }

    if (additionalClassNames) {
        classes.push(...additionalClassNames.filter((value): value is string => typeof value === "string"));
    }

    return classes.join(" ");
};
