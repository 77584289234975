import { RouteObject } from "react-router-dom";
import { ProtectedRoute } from "src/authentication/components/ProtectedRoute";
import { IconCalendarEvent, IconHome, IconProgressCheck } from "@tabler/icons-react";

export const publicRoutes: RouteObject[] = [
    {
        element: <ProtectedRoute publicPermitted />,

        children: [
            {
                index: true,
                lazy: () => import("./authenticated-index/AuthenticatedPublicIndex"),
                handle: { crumb: "Home", icon: IconHome },
            },

            {
                path: "event-bookings",
                lazy: () => import("./event-bookings/PublicEventBookingsOutlet"),

                children: [
                    {
                        index: true,
                        lazy: () => import("./event-bookings/PublicEventsScreen"),
                        handle: { crumb: "Event bookings", icon: IconCalendarEvent },
                    },

                    {
                        path: "event/:eventId",
                        lazy: () => import("./event-bookings/booking-form/PublicEventBookingScreen"),
                    },
                ],
            },

            {
                path: "consent-and-medical",
                handle: { crumb: "Consent and medical forms", icon: IconProgressCheck },
                children: [
                    { index: true, lazy: () => import("./consent-and-medical/PublicConsentAndMedicalScreen") },

                    {
                        path: "give-parental-consent",

                        children: [
                            {
                                index: true,
                                lazy: () =>
                                    import(
                                        "./consent-and-medical/give-parental-consent/PublicGiveParentalConsentInformation"
                                    ),
                            },

                            {
                                lazy: () =>
                                    import(
                                        "./consent-and-medical/give-parental-consent/PublicGiveParentalConsentScreen"
                                    ),
                                children: [
                                    {
                                        path: "about-your-child",
                                        lazy: () =>
                                            import(
                                                "./consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormAboutYourChildStep"
                                            ),
                                    },
                                    {
                                        path: "your-details",
                                        lazy: () =>
                                            import(
                                                "./consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormYourDetailsStep"
                                            ),
                                    },
                                    {
                                        path: "consent",
                                        lazy: () =>
                                            import(
                                                "./consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormConsentStep"
                                            ),
                                    },
                                    {
                                        path: "declaration",
                                        lazy: () =>
                                            import(
                                                "./consent-and-medical/give-parental-consent/steps/PublicGiveParentalConsentFormDeclarationStep"
                                            ),
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "fill-out-a-medical-form/:parentalConsentFormId",
                        lazy: () =>
                            import("./consent-and-medical/fill-out-a-medical-form/PublicParentalConsentFormOutlet"),

                        children: [
                            {
                                index: true,
                                lazy: () =>
                                    import(
                                        "./consent-and-medical/fill-out-a-medical-form/PublicFillOutMedicalFormInformation"
                                    ),
                            },
                            {
                                path: "start",
                                lazy: () =>
                                    import(
                                        "./consent-and-medical/fill-out-a-medical-form/PublicFillOutMedicalFormPresenter"
                                    ),
                            },
                        ],
                    },

                    {
                        path: "register-for-ministry-season/:ministrySeasonId",
                        lazy: () =>
                            import(
                                "./consent-and-medical/register-for-ministry-season/PublicMinistrySeasonRegistrationScreen"
                            ),
                    },
                ],
            },
        ],
    },
];
